<template>
  <div class="text-darkBlue relative text-6xl">
    <div class="flex items-center">
      <button
        class="mr-30 transition-all duration-300 max-md:absolute max-md:bottom-[-100px] max-md:left-0 max-sm:bottom-[-65px]"
        v-if="dataArr.length > 3"
        @click="
          () => {
            sl.prev();
          }
        "
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="60"
          height="60"
          viewBox="0 0 60 60"
          fill="none"
          class="max-sm:h-[32px] max-sm:w-[32px]"
        >
          <g clip-path="url(#clip0_212_469)">
            <path
              d="M30.0038 60.0076C46.5745 60.0076 60.0076 46.5745 60.0076 30.0038C60.0076 13.4332 46.5745 0 30.0038 0C13.4332 0 0 13.4332 0 30.0038C0 46.5745 13.4332 60.0076 30.0038 60.0076Z"
              fill="#2B3483"
            />
            <path
              d="M29.0897 32.7917C24.5347 29.2497 19.4693 26.3857 14.0764 24.3367L14.1678 27.9777C18.5705 25.3269 22.9732 22.6838 27.3759 20.033C28.1605 19.5608 28.3661 18.5401 28.1833 17.6869C28.0614 17.108 27.414 15.9274 26.6218 16.4073C22.2191 19.058 17.8164 21.7012 13.4137 24.3519C12.3092 25.0146 12.134 27.4749 13.5051 27.9929C18.6924 29.9657 23.5369 32.6774 27.9167 36.0823C29.5239 37.3315 30.3161 33.7438 29.0897 32.7917Z"
              fill="white"
            />
            <path
              d="M46.4035 36.3794C44.5068 31.6721 41.2543 27.5512 36.6612 25.2584C32.7689 23.3161 28.2824 22.7219 23.9863 23.2018C21.7317 23.4532 19.5075 24.0016 17.3899 24.809C16.354 25.2051 16.5977 28.9527 17.8317 28.4805C25.2965 25.6393 34.7036 26.2715 40.6449 32.0377C42.6787 34.0181 44.1641 36.448 45.2152 39.0759C45.5047 39.7919 46.0912 39.7081 46.4035 39.0759C46.7843 38.2913 46.7234 37.1716 46.4035 36.3794Z"
              fill="white"
            />
          </g>
          <defs>
            <clipPath id="clip0_212_469">
              <rect width="60" height="60" fill="white" />
            </clipPath>
          </defs>
        </svg>
      </button>
      <div ref="sl" class="keen-slider sl-slider">
        <div
          v-for="item in this.dataArr"
          :key="item.id"
          class="keen-slider__slide group my-10 flex flex-col overflow-hidden overflow-hidden rounded-lg bg-[#CDDDED] px-40 hover:shadow-[5px_5px_0px_0px_#2B3483] max-sm:p-20 sm:h-400 sm:pb-50"
          :class="{
            '!bg-[#F6C4BD]': item?.sku === 'BOOMERSBLEND',
            'bg-[#E1CCEC]': item?.sku === 'KNIGHTSBLEND',
            '!bg-[#F6C4BD]': item?.sku === 'HUNTERSBLEND',
          }"
        >
          <a
            :href="item.url"
            class="group group relative mx-auto mb-20 block transition-all transition-all transition-all duration-300 duration-300 duration-500 hover:scale-105 group-hover:pt-0 md:pt-50"
          >
            <img
              :src="item.featured_image"
              :alt="item.title"
              class="mx-auto object-cover sm:h-160"
            />
          </a>
          <a
            :href="item.url"
            class="text-center text-xl font-bold max-sm:text-xs"
          >
            {{ item?.sku }}
          </a>
          <a
            :href="item.url"
            class="flex-auto text-center text-[18px] leading-6 max-sm:mt-10 max-sm:text-base max-sm:leading-5"
          >
            {{ item?.title }}
          </a>
          <a
            :href="item.url"
            class="border-b-darkBlue mx-auto mt-15 inline-block max-w-[142px] border-b-[3px] text-lg font-bold uppercase max-sm:text-sm md:hidden md:group-hover:block"
          >
            Learn More
          </a>
        </div>
      </div>
      <button
        class="ml-30 transition-all duration-300 hover:opacity-80 max-md:absolute max-md:bottom-[-100px] max-md:right-0 max-sm:bottom-[-65px]"
        v-if="dataArr.length > 3"
        @click="
          () => {
            sl.next();
          }
        "
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="60"
          height="60"
          viewBox="0 0 60 60"
          fill="none"
          class="max-sm:h-[32px] max-sm:w-[32px]"
        >
          <g clip-path="url(#clip0_212_473)">
            <path
              d="M29.9962 60.0076C13.4255 60.0076 -0.00762177 46.5745 -0.00762177 30.0038C-0.00762177 13.4332 13.4255 0 29.9962 0C46.5668 0 60 13.4332 60 30.0038C60 46.5745 46.5668 60.0076 29.9962 60.0076Z"
              fill="#2B3483"
            />
            <path
              d="M30.9103 32.7917C35.4653 29.2497 40.5307 26.3857 45.9236 24.3367L45.8322 27.9777C41.4295 25.3269 37.0268 22.6838 32.6241 20.033C31.8395 19.5608 31.6339 18.5401 31.8167 17.6869C31.9386 17.108 32.586 15.9274 33.3782 16.4073C37.7809 19.058 42.1836 21.7012 46.5863 24.3519C47.6908 25.0146 47.866 27.4749 46.4949 27.9929C41.3076 29.9657 36.4631 32.6774 32.0833 36.0823C30.4761 37.3315 29.6839 33.7438 30.9103 32.7917Z"
              fill="white"
            />
            <path
              d="M13.5965 36.3794C15.4932 31.6721 18.7457 27.5512 23.3388 25.2584C27.2311 23.3161 31.7176 22.7219 36.0137 23.2018C38.2683 23.4532 40.4925 24.0016 42.6101 24.809C43.646 25.2051 43.4023 28.9527 42.1683 28.4805C34.7035 25.6393 25.2964 26.2715 19.3551 32.0377C17.3213 34.0181 15.8359 36.448 14.7848 39.0759C14.4953 39.7919 13.9088 39.7081 13.5965 39.0759C13.2157 38.2913 13.2766 37.1716 13.5965 36.3794Z"
              fill="white"
            />
          </g>
          <defs>
            <clipPath id="clip0_212_473">
              <rect
                width="60"
                height="60"
                fill="white"
                transform="matrix(-1 0 0 1 60 0)"
              />
            </clipPath>
          </defs>
        </svg>
      </button>
    </div>
  </div>
</template>

<script>
import KeenSlider from "keen-slider";
import "keen-slider/keen-slider.min.css";

export default {
  name: "collection-slider-old",
  props: {
    data: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      dataArr: JSON.parse(this.data),
      current: 0,
      sl: null,
    };
  },

  methods: {
    initSlider() {
      setTimeout(() => {
        this.sl = new KeenSlider(this.$refs.sl, {
          loop: false,
          rtl: false,
          initial: this.current,
          slideChanged: s => {
            this.current = s.track.details.rel;
          },
          breakpoints: {
            "(max-width: 1024px)": {
              slides: {
                perView: 2,
                spacing: 10,
              },
            },
          },
          slides: {
            perView: 3,
            spacing: 35,
          },
        });
      }, 1);
    },
  },
  mounted() {
    // console.log(this.dataArr);
    this.initSlider();
  },
};
</script>
